var exports = {};
exports = {
  A: {
    A: {
      "2": "K E F G A bC",
      "2052": "B"
    },
    B: {
      "1": "1 2 3 4 5 6 7 8 9 C L M H N O P Q I R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u AB BB CB DB EB FB D"
    },
    C: {
      "1": "1 2 3 4 5 6 7 8 9 SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB EC pB FC qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B Q I R GC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u AB BB CB DB EB FB D HC IC JC dC eC",
      "132": "cC DC J GB K E F G A B C fC gC",
      "260": "0 L M H N O P HB v w x y z IB JB KB LB MB NB OB PB QB RB"
    },
    D: {
      "1": "1 2 3 4 5 6 7 8 9 fB gB hB iB jB kB lB mB nB oB EC pB FC qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B Q I R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u AB BB CB DB EB FB D HC IC JC",
      "260": "J GB K E F G A B C L M H N O P HB v",
      "772": "0 w x y z IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB",
      "1028": "XB YB ZB aB bB cB dB eB"
    },
    E: {
      "1": "B C L M H 7B 8B mC nC oC MC NC 9B pC AC OC PC QC RC SC qC BC TC UC VC WC XC YC CC rC",
      "260": "J GB A hC KC LC",
      "772": "K E F G iC jC kC lC"
    },
    F: {
      "1": "SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B Q I R GC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u",
      "2": "G sC",
      "132": "B tC uC vC 7B ZC",
      "644": "C wC 8B",
      "772": "0 H N O P HB v w x y z IB JB",
      "1028": "KB LB MB NB OB PB QB RB"
    },
    G: {
      "1": "6C 7C 8C 9C AD BD CD DD ED FD GD MC NC 9B HD AC OC PC QC RC SC ID BC TC UC VC WC XC YC CC",
      "260": "KC xC aC 4C 5C",
      "772": "F yC zC 0C 1C 2C 3C"
    },
    H: {
      "644": "JD"
    },
    I: {
      "1": "D",
      "16": "KD LD",
      "260": "MD",
      "772": "DC J ND aC OD PD"
    },
    J: {
      "772": "E A"
    },
    K: {
      "1": "I",
      "132": "A B 7B ZC",
      "644": "C 8B"
    },
    L: {
      "1": "D"
    },
    M: {
      "1": "D"
    },
    N: {
      "1": "B",
      "2": "A"
    },
    O: {
      "1": "9B"
    },
    P: {
      "1": "0 v w x y z QD RD SD TD UD LC VD WD XD YD ZD AC BC CC aD",
      "1028": "J"
    },
    Q: {
      "1": "bD"
    },
    R: {
      "1": "cD"
    },
    S: {
      "1": "dD eD"
    }
  },
  B: 6,
  C: "const",
  D: true
};
export default exports;