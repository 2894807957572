var exports = {};
exports = {
  A: {
    A: {
      "2": "K E F G A B bC"
    },
    B: {
      "1": "Q I R S T U V W X Y Z",
      "2": "C L M H",
      "194": "N O P",
      "513": "1 2 3 4 5 6 7 8 9 a b c d e f g h i j k l m n o p q r s t u AB BB CB DB EB FB D"
    },
    C: {
      "2": "0 cC DC J GB K E F G A B C L M H N O P HB v w x y z IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB fC gC",
      "194": "nB oB EC pB FC qB rB sB tB uB vB wB xB yB zB 0B 1B",
      "450": "2B 3B 4B 5B 6B",
      "513": "1 2 3 4 5 6 7 8 9 Q I R GC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u AB BB CB DB EB FB D HC IC JC dC eC"
    },
    D: {
      "1": "wB xB yB zB 0B 1B 2B 3B 4B 5B 6B Q I R S T U V W X Y Z",
      "2": "0 J GB K E F G A B C L M H N O P HB v w x y z IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB EC",
      "194": "pB FC qB rB sB tB uB vB",
      "513": "1 2 3 4 5 6 7 8 9 a b c d e f g h i j k l m n o p q r s t u AB BB CB DB EB FB D HC IC JC"
    },
    E: {
      "2": "J GB K E F G A hC KC iC jC kC lC",
      "194": "B C L M H LC 7B 8B mC nC oC",
      "513": "MC NC 9B pC AC OC PC QC RC SC qC BC TC UC VC WC XC YC CC rC"
    },
    F: {
      "1": "sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B",
      "2": "0 G B C H N O P HB v w x y z IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB sC tC uC vC 7B ZC wC 8B",
      "194": "dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB",
      "513": "6B Q I R GC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u"
    },
    G: {
      "2": "F KC xC aC yC zC 0C 1C 2C 3C 4C",
      "194": "5C 6C 7C 8C 9C AD BD CD DD ED FD GD",
      "513": "MC NC 9B HD AC OC PC QC RC SC ID BC TC UC VC WC XC YC CC"
    },
    H: {
      "2": "JD"
    },
    I: {
      "2": "DC J D KD LD MD ND aC OD PD"
    },
    J: {
      "2": "E A"
    },
    K: {
      "2": "A B C 7B ZC 8B",
      "513": "I"
    },
    L: {
      "513": "D"
    },
    M: {
      "513": "D"
    },
    N: {
      "2": "A B"
    },
    O: {
      "1": "9B"
    },
    P: {
      "2": "J QD RD SD TD UD LC VD WD XD YD",
      "513": "0 v w x y z ZD AC BC CC aD"
    },
    Q: {
      "2": "bD"
    },
    R: {
      "513": "cD"
    },
    S: {
      "2": "dD",
      "513": "eD"
    }
  },
  B: 6,
  C: "Shared Array Buffer",
  D: true
};
export default exports;