var exports = {};
exports = {
  A: {
    A: {
      "2": "K E F G A B bC"
    },
    B: {
      "1": "D",
      "2": "C L M H N O P",
      "194": "6 7 8 9 AB BB CB DB EB FB",
      "962": "1 2 3 4 5 Q I R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u"
    },
    C: {
      "1": "7 8 9 AB BB CB DB EB FB D HC IC JC dC eC",
      "2": "cC",
      "516": "1 2 3 4 5 6 b c d e f g h i j k l m n o p q r s t u",
      "772": "0 DC J GB K E F G A B C L M H N O P HB v w x y z IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB EC pB FC qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B Q I R GC S T U V W X Y Z a fC gC"
    },
    D: {
      "1": "D HC IC JC",
      "2": "0 J GB K E F G A B C L M H N O P HB v w x y z IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB",
      "194": "9 AB BB CB DB EB FB",
      "962": "1 2 3 4 5 6 7 8 ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB EC pB FC qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B Q I R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u"
    },
    E: {
      "1": "BC TC UC VC WC XC YC CC rC",
      "2": "J GB K E F G A B C L M H hC KC iC jC kC lC LC 7B 8B mC nC oC MC NC 9B pC AC OC PC QC",
      "772": "RC SC qC"
    },
    F: {
      "2": "0 G B C H N O P HB v w x y z IB JB KB LB sC tC uC vC 7B ZC wC 8B",
      "194": "l m n o p q r s t u",
      "962": "MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B Q I R GC S T U V W X Y Z a b c d e f g h i j k"
    },
    G: {
      "1": "BC TC UC VC WC XC YC CC",
      "2": "F KC xC aC yC zC 0C 1C 2C 3C 4C 5C 6C 7C 8C 9C AD BD CD DD ED FD GD MC NC 9B HD AC OC PC QC",
      "772": "RC SC ID"
    },
    H: {
      "2": "JD"
    },
    I: {
      "1": "D",
      "2": "DC J KD LD MD ND aC OD PD"
    },
    J: {
      "2": "E A"
    },
    K: {
      "2": "A B C I 7B ZC 8B"
    },
    L: {
      "1": "D"
    },
    M: {
      "1": "D"
    },
    N: {
      "2": "A B"
    },
    O: {
      "2": "9B"
    },
    P: {
      "2": "0 J v w x y z QD RD SD TD UD LC VD WD XD YD ZD AC BC CC aD"
    },
    Q: {
      "194": "bD"
    },
    R: {
      "2": "cD"
    },
    S: {
      "2": "dD",
      "516": "eD"
    }
  },
  B: 2,
  C: "CSS font-size-adjust",
  D: true
};
export default exports;