var exports = {};
exports = {
  A: {
    A: {
      "2": "K E F G A B bC"
    },
    B: {
      "1": "1 2 3 4 5 6 7 8 9 Q I R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u AB BB CB DB EB FB D",
      "2": "C L M H N O P"
    },
    C: {
      "1": "0 1 2 3 4 5 6 7 8 9 J GB K E F G A B C L M H N O P HB v w x y z IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB EC pB FC qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B Q I R GC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u AB BB CB DB EB FB D HC IC JC dC eC",
      "16": "cC DC fC gC"
    },
    D: {
      "1": "1 2 3 4 5 6 7 8 9 hB iB jB kB lB mB nB oB EC pB FC qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B Q I R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u AB BB CB DB EB FB D HC IC JC",
      "16": "J GB K E F G A B C L M",
      "132": "0 H N O P HB v w x y z IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB"
    },
    E: {
      "1": "B C L M H LC 7B 8B mC nC oC MC NC 9B pC AC OC PC QC RC SC qC BC TC UC VC WC XC YC CC rC",
      "16": "J GB hC KC",
      "132": "K E F G A iC jC kC lC"
    },
    F: {
      "1": "UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B Q I R GC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u",
      "16": "G B sC tC uC vC 7B ZC",
      "132": "0 H N O P HB v w x y z IB JB KB LB MB NB OB PB QB RB SB TB",
      "260": "C wC 8B"
    },
    G: {
      "1": "5C 6C 7C 8C 9C AD BD CD DD ED FD GD MC NC 9B HD AC OC PC QC RC SC ID BC TC UC VC WC XC YC CC",
      "16": "KC xC aC yC zC",
      "132": "F 0C 1C 2C 3C 4C"
    },
    H: {
      "260": "JD"
    },
    I: {
      "1": "D",
      "16": "DC KD LD MD",
      "132": "J ND aC OD PD"
    },
    J: {
      "16": "E",
      "132": "A"
    },
    K: {
      "1": "I",
      "16": "A B C 7B ZC",
      "260": "8B"
    },
    L: {
      "1": "D"
    },
    M: {
      "1": "D"
    },
    N: {
      "2": "A B"
    },
    O: {
      "1": "9B"
    },
    P: {
      "1": "0 v w x y z QD RD SD TD UD LC VD WD XD YD ZD AC BC CC aD",
      "132": "J"
    },
    Q: {
      "1": "bD"
    },
    R: {
      "1": "cD"
    },
    S: {
      "1": "dD eD"
    }
  },
  B: 5,
  C: ":default CSS pseudo-class",
  D: true
};
export default exports;