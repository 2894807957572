var exports = {};
exports = {
  A: {
    A: {
      "2": "K E F G A B bC"
    },
    B: {
      "2": "C L M",
      "33": "1 2 3 4 5 6 7 8 9 Q I R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u AB BB CB DB EB FB D",
      "161": "H N O P"
    },
    C: {
      "2": "0 cC DC J GB K E F G A B C L M H N O P HB v w x y z IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB fC gC",
      "161": "1 2 3 4 5 6 7 8 9 fB gB hB iB jB kB lB mB nB oB EC pB FC qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B Q I R GC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u AB BB CB DB EB FB D HC IC JC dC eC",
      "450": "eB"
    },
    D: {
      "33": "0 1 2 3 4 5 6 7 8 9 J GB K E F G A B C L M H N O P HB v w x y z IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB EC pB FC qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B Q I R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u AB BB CB DB EB FB D HC IC JC"
    },
    E: {
      "33": "J GB K E F G A B C L M H hC KC iC jC kC lC LC 7B 8B mC nC oC MC NC 9B pC AC OC PC QC RC SC qC BC TC UC VC WC XC YC CC rC"
    },
    F: {
      "2": "G B C sC tC uC vC 7B ZC wC 8B",
      "33": "0 H N O P HB v w x y z IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B Q I R GC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u"
    },
    G: {
      "33": "F xC aC yC zC 0C 1C 2C 3C 4C 5C 6C 7C 8C 9C AD BD CD DD ED FD GD MC NC 9B HD AC OC PC QC RC SC ID BC TC UC VC WC XC YC CC",
      "36": "KC"
    },
    H: {
      "2": "JD"
    },
    I: {
      "2": "DC",
      "33": "J D KD LD MD ND aC OD PD"
    },
    J: {
      "33": "E A"
    },
    K: {
      "2": "A B C 7B ZC 8B",
      "33": "I"
    },
    L: {
      "33": "D"
    },
    M: {
      "161": "D"
    },
    N: {
      "2": "A B"
    },
    O: {
      "33": "9B"
    },
    P: {
      "33": "0 J v w x y z QD RD SD TD UD LC VD WD XD YD ZD AC BC CC aD"
    },
    Q: {
      "33": "bD"
    },
    R: {
      "33": "cD"
    },
    S: {
      "161": "dD eD"
    }
  },
  B: 7,
  C: "CSS text-stroke and text-fill",
  D: true
};
export default exports;