var exports = {};
exports = {
  A: {
    A: {
      "1": "A B",
      "2": "K E F G bC"
    },
    B: {
      "1": "1 2 3 4 5 6 7 8 9 C L M H N O P Q I R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u AB BB CB DB EB FB D"
    },
    C: {
      "1": "0 1 2 3 4 5 6 7 8 9 DC J GB K E F G A B C L M H N O P HB v w x y z IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB EC pB FC qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B Q I R GC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u AB BB CB DB EB FB D HC IC JC dC eC fC gC",
      "2": "cC"
    },
    D: {
      "1": "0 1 2 3 4 5 6 7 8 9 F G A B C L M H N O P HB v w x y z IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB EC pB FC qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B Q I R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u AB BB CB DB EB FB D HC IC JC",
      "2": "J",
      "4": "GB K E"
    },
    E: {
      "1": "K E F G A B C L M H jC kC lC LC 7B 8B mC nC oC MC NC 9B pC AC OC PC QC RC SC qC BC TC UC VC WC XC YC CC rC",
      "2": "J GB hC KC iC"
    },
    F: {
      "1": "0 G B C H N O P HB v w x y z IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B Q I R GC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u sC tC uC vC 7B ZC wC 8B"
    },
    G: {
      "1": "F zC 0C 1C 2C 3C 4C 5C 6C 7C 8C 9C AD BD CD DD ED FD GD MC NC 9B HD AC OC PC QC RC SC ID BC TC UC VC WC XC YC CC",
      "2": "KC xC aC yC"
    },
    H: {
      "1": "JD"
    },
    I: {
      "1": "D OD PD",
      "2": "DC J KD LD MD ND aC"
    },
    J: {
      "1": "A",
      "2": "E"
    },
    K: {
      "1": "A B C I 7B ZC 8B"
    },
    L: {
      "1": "D"
    },
    M: {
      "1": "D"
    },
    N: {
      "1": "A B"
    },
    O: {
      "1": "9B"
    },
    P: {
      "1": "0 J v w x y z QD RD SD TD UD LC VD WD XD YD ZD AC BC CC aD"
    },
    Q: {
      "1": "bD"
    },
    R: {
      "1": "cD"
    },
    S: {
      "1": "dD eD"
    }
  },
  B: 2,
  C: "SVG filters",
  D: true
};
export default exports;