var exports = {};
exports = {
  A: {
    A: {
      "1": "B",
      "2": "K E bC",
      "66": "F G A"
    },
    B: {
      "1": "1 2 3 4 5 6 7 8 9 C L M H N O P Q I R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u AB BB CB DB EB FB D"
    },
    C: {
      "1": "0 z IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB EC pB FC qB rB sB tB uB vB",
      "2": "cC DC J GB K E F G A B C L M H N O P HB v w x fC gC",
      "66": "y",
      "129": "wB xB yB zB 0B 1B 2B 3B 4B 5B",
      "388": "1 2 3 4 5 6 7 8 9 6B Q I R GC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u AB BB CB DB EB FB D HC IC JC dC eC"
    },
    D: {
      "1": "0 x y z IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB EC pB FC qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B Q I R S T",
      "2": "J GB K E F G A B C L M H N O P HB v w",
      "1540": "1 2 3 4 5 6 7 8 9 U V W X Y Z a b c d e f g h i j k l m n o p q r s t u AB BB CB DB EB FB D HC IC JC"
    },
    E: {
      "1": "E F G A B C L kC lC LC 7B 8B",
      "2": "J GB K hC KC iC jC",
      "513": "M H mC nC oC MC NC 9B pC AC OC PC QC RC SC qC BC TC UC VC WC XC YC CC rC"
    },
    F: {
      "1": "0 H N O P HB v w x y z IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB tB uB vB wB xB yB zB 0B 8B",
      "2": "G B C sC tC uC vC 7B ZC wC",
      "1540": "1B 2B 3B 4B 5B 6B Q I R GC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u"
    },
    G: {
      "1": "F yC zC 0C 1C 2C 3C 4C 5C 6C 7C 8C 9C AD BD CD DD ED FD GD MC NC 9B HD AC OC PC QC RC SC ID BC TC UC VC WC XC YC CC",
      "2": "KC xC aC"
    },
    H: {
      "1": "JD"
    },
    I: {
      "1": "D",
      "2": "DC J KD LD MD ND aC OD PD"
    },
    J: {
      "1": "A",
      "2": "E"
    },
    K: {
      "1": "I 8B",
      "2": "A B C 7B ZC"
    },
    L: {
      "1": "D"
    },
    M: {
      "129": "D"
    },
    N: {
      "1": "B",
      "66": "A"
    },
    O: {
      "1": "9B"
    },
    P: {
      "1": "0 J v w x y z QD RD SD TD UD LC VD WD XD YD ZD AC BC CC aD"
    },
    Q: {
      "1": "bD"
    },
    R: {
      "1": "cD"
    },
    S: {
      "1": "dD eD"
    }
  },
  B: 6,
  C: "TLS 1.1",
  D: true
};
export default exports;