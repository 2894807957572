var exports = {};
exports = {
  A: {
    A: {
      "2": "K E F G A B bC"
    },
    B: {
      "1": "3 4 5 6 7 8 9 AB BB CB DB EB FB D",
      "2": "1 2 C L M H N O P Q I R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u"
    },
    C: {
      "2": "0 cC DC J GB K E F G A B C L M H N O P HB v w x y z IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB EC pB FC qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B Q I R GC S T U V W X Y Z a b c d e f g fC gC",
      "194": "1 2 3 4 5 6 7 8 9 h i j k l m n o p q r s t u AB BB CB DB EB FB D HC IC JC dC eC"
    },
    D: {
      "1": "3 4 5 6 7 8 9 AB BB CB DB EB FB D HC IC JC",
      "2": "0 1 2 J GB K E F G A B C L M H N O P HB v w x y z IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB EC pB FC qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B Q I R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u"
    },
    E: {
      "2": "J GB K E F G A B C L M H hC KC iC jC kC lC LC 7B 8B mC nC oC MC NC 9B pC AC OC PC QC RC SC qC BC TC UC VC WC XC YC CC rC"
    },
    F: {
      "1": "j k l m n o p q r s t u",
      "2": "0 G B C H N O P HB v w x y z IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B Q I R GC S T U V W X Y Z a b c d e f g h i sC tC uC vC 7B ZC wC 8B"
    },
    G: {
      "2": "F KC xC aC yC zC 0C 1C 2C 3C 4C 5C 6C 7C 8C 9C AD BD CD DD ED FD GD MC NC 9B HD AC OC PC QC RC SC ID BC TC UC VC WC XC YC CC"
    },
    H: {
      "2": "JD"
    },
    I: {
      "1": "D",
      "2": "DC J KD LD MD ND aC OD PD"
    },
    J: {
      "2": "E A"
    },
    K: {
      "2": "A B C I 7B ZC 8B"
    },
    L: {
      "1": "D"
    },
    M: {
      "1": "D"
    },
    N: {
      "2": "A B"
    },
    O: {
      "1": "9B"
    },
    P: {
      "1": "0 y z",
      "2": "J v w x QD RD SD TD UD LC VD WD XD YD ZD AC BC CC aD"
    },
    Q: {
      "16": "bD"
    },
    R: {
      "16": "cD"
    },
    S: {
      "2": "dD",
      "16": "eD"
    }
  },
  B: 5,
  C: "WebAssembly Relaxed SIMD",
  D: false
};
export default exports;