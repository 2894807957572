var exports = {};
exports = {
  A: {
    A: {
      "2": "bC",
      "8": "K E F G",
      "260": "A B"
    },
    B: {
      "1": "1 2 3 4 5 6 7 8 9 Q I R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u AB BB CB DB EB FB D",
      "260": "C L M H",
      "1284": "N O P"
    },
    C: {
      "1": "1 2 3 4 5 6 7 8 9 t u AB BB CB DB EB FB D HC IC JC dC eC",
      "8": "cC DC fC gC",
      "516": "l m n o p q r s",
      "4612": "0 J GB K E F G A B C L M H N O P HB v w x y z IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB EC pB FC qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B Q I R GC S T U V W X Y Z a b c d e f g h i j k"
    },
    D: {
      "1": "1 2 3 4 5 6 7 8 9 xB yB zB 0B 1B 2B 3B 4B 5B 6B Q I R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u AB BB CB DB EB FB D HC IC JC",
      "8": "J GB K E F G A B C L M H N O P HB",
      "132": "0 v w x y z IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB EC pB FC qB rB sB tB uB vB wB"
    },
    E: {
      "1": "L M H 8B mC nC oC MC NC 9B pC AC OC PC QC RC SC qC BC TC UC VC WC XC YC CC rC",
      "8": "J GB K E F G A B C hC KC iC jC kC lC LC 7B"
    },
    F: {
      "1": "G B C sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B Q I R GC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u sC tC uC vC 7B ZC wC 8B",
      "132": "0 H N O P HB v w x y z IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB"
    },
    G: {
      "8": "F KC xC aC yC zC 0C 1C 2C 3C 4C 5C 6C 7C 8C",
      "2049": "9C AD BD CD DD ED FD GD MC NC 9B HD AC OC PC QC RC SC ID BC TC UC VC WC XC YC CC"
    },
    H: {
      "2": "JD"
    },
    I: {
      "1": "D PD",
      "8": "DC J KD LD MD ND aC OD"
    },
    J: {
      "1": "A",
      "8": "E"
    },
    K: {
      "1": "A B C I 7B ZC 8B"
    },
    L: {
      "1": "D"
    },
    M: {
      "2": "D"
    },
    N: {
      "8": "A B"
    },
    O: {
      "1": "9B"
    },
    P: {
      "1": "0 J v w x y z QD RD SD TD UD LC VD WD XD YD ZD AC BC CC aD"
    },
    Q: {
      "1": "bD"
    },
    R: {
      "1": "cD"
    },
    S: {
      "2": "dD eD"
    }
  },
  B: 1,
  C: "Datalist element",
  D: true
};
export default exports;