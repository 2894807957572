var exports = {};
exports = {
  A: {
    A: {
      "2": "K E F G bC",
      "130": "A B"
    },
    B: {
      "1": "1 2 3 4 5 6 7 8 9 u AB BB CB DB EB FB D",
      "130": "C L M H N O P Q I R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t"
    },
    C: {
      "1": "1 2 3 4 5 6 7 8 9 QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB EC pB FC qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B Q I R GC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u AB BB CB DB EB FB D HC IC JC dC eC",
      "2": "cC DC fC gC",
      "130": "J GB K E F G A B C L M H N O P HB v w x y",
      "322": "0 z IB JB KB LB MB NB OB PB"
    },
    D: {
      "1": "1 2 3 4 5 6 7 8 9 u AB BB CB DB EB FB D HC IC JC",
      "2": "J GB K E F G A B C L M H",
      "130": "0 N O P HB v w x y z IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB EC pB FC qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B Q I R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t"
    },
    E: {
      "1": "A B C L M H lC LC 7B 8B mC nC oC MC NC 9B pC AC OC PC QC RC SC qC BC TC UC VC WC XC YC CC rC",
      "2": "E F G hC KC jC kC",
      "130": "J GB K iC"
    },
    F: {
      "1": "h i j k l m n o p q r s t u",
      "2": "G B C sC tC uC vC 7B ZC wC 8B",
      "130": "0 H N O P HB v w x y z IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B Q I R GC S T U V W X Y Z a b c d e f g"
    },
    G: {
      "1": "3C 4C 5C 6C 7C 8C 9C AD BD CD DD ED FD GD MC NC 9B HD AC OC PC QC RC SC ID BC TC UC VC WC XC YC CC",
      "2": "F KC 0C 1C 2C",
      "130": "xC aC yC zC"
    },
    H: {
      "2": "JD"
    },
    I: {
      "1": "D",
      "2": "DC J KD LD MD ND aC",
      "130": "OD PD"
    },
    J: {
      "2": "E",
      "130": "A"
    },
    K: {
      "1": "I",
      "2": "A B C 7B ZC 8B"
    },
    L: {
      "1": "D"
    },
    M: {
      "1": "D"
    },
    N: {
      "2": "A B"
    },
    O: {
      "130": "9B"
    },
    P: {
      "1": "0 x y z",
      "130": "J v w QD RD SD TD UD LC VD WD XD YD ZD AC BC CC aD"
    },
    Q: {
      "130": "bD"
    },
    R: {
      "130": "cD"
    },
    S: {
      "1": "dD eD"
    }
  },
  B: 5,
  C: "CSS font-variant-alternates",
  D: true
};
export default exports;