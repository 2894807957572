var exports = {};
exports = {
  A: {
    A: {
      "2436": "K E F G A B bC"
    },
    B: {
      "260": "O P",
      "2436": "C L M H N",
      "8196": "1 2 3 4 5 6 7 8 9 Q I R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u AB BB CB DB EB FB D"
    },
    C: {
      "2": "cC DC J GB K E F G A B C L M H N O P HB v w fC gC",
      "772": "0 x y z IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB",
      "4100": "1 2 3 4 5 6 7 8 9 XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB EC pB FC qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B Q I R GC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u AB BB CB DB EB FB D HC IC JC dC eC"
    },
    D: {
      "2": "J GB K E F G A B C",
      "2564": "0 L M H N O P HB v w x y z IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB",
      "8196": "1 2 3 4 5 6 7 8 9 oB EC pB FC qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B Q I R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u AB BB CB DB EB FB D HC IC JC",
      "10244": "ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB"
    },
    E: {
      "1": "C L M H 8B mC nC oC MC NC 9B pC AC OC PC QC RC SC qC BC TC UC VC WC XC YC CC rC",
      "16": "hC KC",
      "2308": "A B LC 7B",
      "2820": "J GB K E F G iC jC kC lC"
    },
    F: {
      "2": "G B sC tC uC vC 7B ZC wC",
      "16": "C",
      "516": "8B",
      "2564": "0 H N O P HB v w x y z IB JB KB LB",
      "8196": "bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B Q I R GC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u",
      "10244": "MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB"
    },
    G: {
      "1": "8C 9C AD BD CD DD ED FD GD MC NC 9B HD AC OC PC QC RC SC ID BC TC UC VC WC XC YC CC",
      "2": "KC xC aC",
      "2820": "F yC zC 0C 1C 2C 3C 4C 5C 6C 7C"
    },
    H: {
      "2": "JD"
    },
    I: {
      "2": "DC J KD LD MD ND aC",
      "260": "D",
      "2308": "OD PD"
    },
    J: {
      "2": "E",
      "2308": "A"
    },
    K: {
      "2": "A B C 7B ZC",
      "16": "8B",
      "8196": "I"
    },
    L: {
      "8196": "D"
    },
    M: {
      "1028": "D"
    },
    N: {
      "2": "A B"
    },
    O: {
      "8196": "9B"
    },
    P: {
      "2052": "QD RD",
      "2308": "J",
      "8196": "0 v w x y z SD TD UD LC VD WD XD YD ZD AC BC CC aD"
    },
    Q: {
      "8196": "bD"
    },
    R: {
      "8196": "cD"
    },
    S: {
      "4100": "dD eD"
    }
  },
  B: 5,
  C: "Synchronous Clipboard API",
  D: true
};
export default exports;