var exports = {};
exports = {
  A: {
    A: {
      "2": "K E F G A B bC"
    },
    B: {
      "1": "1 2 3 4 5 6 7 8 9 e f g h i j k l m n o p q r s t u AB BB CB DB EB FB D",
      "2": "C L M H N O P Q I",
      "516": "R S T U V W X Y Z a b c d"
    },
    C: {
      "2": "0 1 2 3 4 5 6 7 8 9 cC DC J GB K E F G A B C L M H N O P HB v w x y z IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB EC pB FC qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B Q I R GC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u AB BB CB DB EB FB D HC IC JC dC eC fC gC"
    },
    D: {
      "2": "0 J GB K E F G A B C L M H N O IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB EC pB FC qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B Q I R S T U V W X",
      "130": "P HB v w x y z",
      "1028": "1 2 3 4 5 6 7 8 9 Y Z a b c d e f g h i j k l m n o p q r s t u AB BB CB DB EB FB D",
      "4100": "HC IC JC"
    },
    E: {
      "1": "M H nC oC MC NC 9B pC AC OC PC QC RC SC qC BC TC UC VC WC XC YC CC rC",
      "2": "J GB K E F G A B C hC KC iC jC kC lC LC 7B",
      "2049": "L 8B mC"
    },
    F: {
      "2": "0 G B C H N O P HB v w x y z IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B Q I R GC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u sC tC uC vC 7B ZC wC 8B"
    },
    G: {
      "1": "ED FD GD MC NC 9B HD AC OC PC QC RC SC ID BC TC UC VC WC XC YC CC",
      "2": "F KC xC aC yC zC 0C 1C 2C 3C 4C 5C 6C 7C 8C",
      "2049": "9C AD BD CD DD"
    },
    H: {
      "2": "JD"
    },
    I: {
      "2": "DC J KD LD MD ND aC OD",
      "258": "D PD"
    },
    J: {
      "2": "E A"
    },
    K: {
      "1": "I",
      "2": "A B C 7B ZC 8B"
    },
    L: {
      "1": "D"
    },
    M: {
      "1": "D"
    },
    N: {
      "2": "A B"
    },
    O: {
      "2": "9B"
    },
    P: {
      "1": "0 v w x y z TD UD LC VD WD XD YD ZD AC BC CC aD",
      "2": "J",
      "258": "QD RD SD"
    },
    Q: {
      "2": "bD"
    },
    R: {
      "2": "cD"
    },
    S: {
      "2": "dD eD"
    }
  },
  B: 4,
  C: "Web Share API",
  D: true
};
export default exports;